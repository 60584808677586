import React, { useState, useEffect } from 'react';
import configuration from "react-global-configuration";
import { connect } from 'react-redux';
import SocialButton from "../helper/SocialButton";
import { translate, t } from "react-multi-lang";
import {
  userRegisterStart,
} from "../../store/actions/UserAction";
import {
  isAndroid,
  isIOS,
  isWindows,
  isMacOs,
  mobileModel,
  browserName,
  osName,
  mobileVendor,
  browserVersion,
} from "react-device-detect";
import { gapi } from "gapi-script";

const SocialLoginSec = (props) => {

  const [additionalDetails, setAdditionalDetails] = useState({
    device_type: "",
    device_model: "",
    browser_type: browserName,
    device_token: "",
  });
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (isAndroid == true) {
      setAdditionalDetails({
        ...additionalDetails,
        device_type: "android",
        device_model: mobileModel,
      });
    } else if (isIOS == true) {
      setAdditionalDetails({
        ...additionalDetails,
        device_type: "ios",
        device_model: mobileModel,
      });
    } else {
      setAdditionalDetails({
        ...additionalDetails,
        device_type: "web",
        device_model: browserName + " " + browserVersion,
      });
    }
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get("configData.GOOGLE_CLIENT_ID"),
        plugin_name: "chat",
      });
    });
  }, []);

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
        device_token: additionalDetails.device_token,
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
        device_token: additionalDetails.device_token,
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  return (
    <>
      {configuration.get("configData.FB_CLIENT_ID") ? (
        <SocialButton
          provider="facebook"
          appId={configuration.get("configData.FB_CLIENT_ID")}
          onLoginSuccess={handleFacebookLogin}
          onLoginFailure={handleSocialLoginFailure}
          className="social-button"
          id="facebook-connect"
        >
          <span>
            {t("signup")} / {t("login_with_facebook")}
          </span>
        </SocialButton>
      ) : (
        ""
      )}

      {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
        <SocialButton
          provider="google"
          key={"google"}
          appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
          onLoginSuccess={handleGoogleLogin}
          onLoginFailure={handleSocialLoginFailure}
          className="social-button"
          id="google-connect"
        >
          <span>
            {t("signup")} / {t("login_with_google")}
          </span>
        </SocialButton>
      ) : (
        ""
      )}
      {configuration.get("configData.GOOGLE_CLIENT_ID") ||
        configuration.get("configData.FB_CLIENT_ID") ? (
        <span className="or-line">
          <span>{t("or")}</span>
        </span>
      ) : (
        <span classsName="login-or-hide"></span>
      )}
    </>
  );
}

const mapStateToPros = (state) => ({
  register: state.users.register,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SocialLoginSec));